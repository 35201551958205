import "typeface-abril-fatface";
import "typeface-roboto";

/* global window, document */
const scrollTo = (id) => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 15)
  return false
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 10)
  }

  // FACEBOOK PIXEL
  // Don't track while developing.
  if (
    process.env.NODE_ENV === `production` && 
    typeof fbq === `function`) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.innerHTML = `
        fbq("track", "View Page");
      `;
      document.body.appendChild(s);
  }
  
}